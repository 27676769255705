<template>
  <div class="app-content">

    <NormalHeader></NormalHeader>

    <div class="chat-app-content">
      <LeftSidebar class="left-sidebar"></LeftSidebar>

      <a-layout-content class="app-content">
        <router-view/>
      </a-layout-content>

    </div>

  </div>
</template>
<script>
import('@/scss/base/_variables.scss')
export default ({
  components: {
    NormalHeader: () => import("@/components/Headers/NormalHeader.vue"),
    LeftSidebar: () => import("@/components/Sidebars/LeftSidebar.vue"),
  },
})

</script>

<style lang="scss">
::v-deep.ant-spin-nested-loading > div > .ant-spin {
  height: calc(100vh - 61px);
}
</style>
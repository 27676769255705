import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import {getToken} from '@/utils/auth'
// import Message from "ant-design-vue/lib/message";
import {generateUUID} from "ant-design-vue/lib/vc-select/util";

// create an axios instance
const service = axios.create({
    timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        const cc = localStorage.getItem("cc")
        if (!cc) {
            localStorage.setItem("cc", generateUUID())
        }
        config.headers['X-client-code'] = localStorage.getItem("cc")
        // config.headers['X-Token'] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb3dUeXBlIjowLCJuYmYiOjE3MTEyMTY1NTQsIm5pY2tuYW1lIjoiSC4iLCJleHAiOjE3MTEzMDI5NTQsImlhdCI6MTcxMTIxNjU1NCwidXNlcklkIjoiMmM5YTk3ZWY4ZTZiZmVmNTAxOGU2YmZmODMzNjAwMDAifQ.LXCA495HeBGy8-DtNc7_TY6ao72YW3KPHmRjtWbOVvw"
        if (store.getters.token) {
            config.headers['X-Token'] = getToken()
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {

        const res = response.data

        // if the custom code is not 20000, it is judged as an error.
        if (!res.success) {

            // Message({
            //   message: res.message || 'Error',
            //   type: 'error',
            //   duration: 5 * 1000
            // })
            //
            // // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
            //   // to re-login
            //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
            //     confirmButtonText: 'Re-Login',
            //     cancelButtonText: 'Cancel',
            //     type: 'warning'
            //   }).then(() => {
            //     store.dispatch('user/resetToken').then(() => {
            //       location.reload()
            //     })
            //   })
            // }
            return res
            // return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log(error) // for debug
        // message.config({
        //   duration: 4, // 提示时常单位为s
        //   top: '40px', // 距离顶部的位置
        //   maxCount: 3 // 最多显示提示信息条数(后面的会替换前面的提示)
        // })
// 在必要的地方(这里是请求失败，返回code !== 200)添加提示信息
//     message.error(error.message ? error.message : 'Error')
        return Promise.reject(error)
    }
)

export default service

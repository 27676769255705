import {login, logout} from '@/api/login'

//logout, getInfo
import {getToken, setToken, removeToken, setName, getName, setUrl, removeName, removeUrl} from '@/utils/auth'
// import {resetRouter} from '@/router'
import {message, Modal} from 'ant-design-vue'

const getDefaultState = () => {
    return {
        token: getToken(),
        name: '',
        avatar: '',
        url:'',
        menus: [],
        permission: [],
        id: '',
        leftBar: []
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_MENUS: (state, menus) => {
        state.menus = menus
    },
    SET_PERMISSION: (state, permission) => {
        state.permission = permission
    },
    SET_ID: (state, id) => {
        state.id = id
    },
    SET_LEFT_BAR(state, leftBar) {
        state.leftBar = leftBar
    }
}
//
const actions = {
    setUser({commit}, userInfo) {
        console.log(userInfo.token, userInfo.url, userInfo.nickname)
        commit('SET_TOKEN', userInfo.token)
        commit('SET_AVATAR', userInfo.url)
        commit('SET_NAME', userInfo.nickname)
        setToken(userInfo.token)
        sessionStorage.setItem("avatar", userInfo.url)
        sessionStorage.setItem("name", userInfo.nickname)
    },


    // user login
    login({commit}, userInfo) {
        return new Promise((resolve, reject) => {
            login(userInfo).then(response => {
                if (response && response.success) {
                    const {data} = response
                    commit('SET_TOKEN', data.token)
                    setToken(data.token)
                    setName(data.userName)
                    // commit('SET_NAME', data.userName)
                    // commit('SET_AVATAR', data.profilePhoto)
                    // commit('SET_ID', data.id)
                } else {
                    this.$message.error(response.message)
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user info
    // getInfo({commit, state}) {
    //   return new Promise((resolve, reject) => {
    //     getInfo(state.token).then(response => {
    //       const {data} = response
    //
    //       if (!data) {
    //         return reject('Verification failed, please Login again.')
    //       }
    //       const {name, avatar, menus, permission} = data
    //
    //       commit('SET_NAME', name)
    //       commit('SET_AVATAR', avatar)
    //       commit('SET_MENUS', menus)
    //       commit('SET_PERMISSION', permission)
    //       resolve(data)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    //
    // user logout
    logout({commit, state}) {
        return new Promise((resolve, reject) => {
            // logout(state.token).then(() => {
                removeToken() // must remove  token  first
                commit('RESET_STATE')
                sessionStorage.removeItem('name')
                sessionStorage.removeItem('avatar')
                resolve()
            // }).catch(error => {
            //     reject(error)
            // })
        })
    },

    // remove token
    // resetToken({commit}) {
    //   return new Promise(resolve => {
    //     removeToken() // must remove  token  first
    //     commit('RESET_STATE')
    //     resolve()
    //   })
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}


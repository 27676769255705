<template>
	<a-layout-footer>
		<p class="copyright">
			Copyright © 2024 跑握AI | 版本号：1.0.1 |  皖ICP备2024040648号
    </p>
	</a-layout-footer>

</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>

<style lang="scss" scoped>

	.nav-link svg {
		margin-right: 5px;
		vertical-align: middle;
	}
	.nav-link span {
		vertical-align: middle;
	}
	.ant-menu-submenu-popup {
		width: 100%;
	}

</style>
import Cookies from 'js-cookie'

const TokenKey = 'powerai_token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}


const nameKey = 'powerai_name'


export function getName() {
    return Cookies.get(nameKey)
}

export function setName(name) {
    return Cookies.set(nameKey, name)
}

export function removeName() {
    return Cookies.remove(nameKey)
}

const urlKey = 'powerai_url'


export function getUrl() {
    return Cookies.get(urlKey)
}

export function setUrl(url) {
    return Cookies.set(urlKey, url)
}

export function removeUrl() {
    return Cookies.remove(urlKey)
}


import request from "@/utils/request";
import {APICONFIG} from "@/utils/apiConfig";


/**
 * 短信验证
 */
export function verificationCode(phone) {
    return request({
        url: `${APICONFIG}/ssm/verification?phoneNum=${phone}`,
        method: 'get',
    })
}


export function getLoginQrCode() {
    return request({
        url: `${APICONFIG}/getLoginQrCode`,
        method: 'get',
    })
}


export function logout() {
    return request({
        url: `${ APICONFIG }/user/logout`,
        method: 'post'
    })
}


export function queryUser(uid) {
    return request({
        url: `${APICONFIG}/query?uid=${uid}`,
        method: 'get',
    })
}




/**
 * 短信验证
 */
export function login(data) {
    return request({
        url: `${APICONFIG}/user/login`,
        method: 'post',
        data
    })
}



import Vue from 'vue'
import VueRouter from 'vue-router'
import request from "@/utils/request";
import {APICONFIG} from "@/utils/apiConfig";


Vue.use(VueRouter)


let routes = [
    {
        path: '*',
        component: () => import('../views/404.vue'),
    },
    {
        path: '/',
        name: '对话聊天',
        redirect: '/app/work',
    },
    {
        "redirect": "/app/work",
        "path": "/app",
        "name": "AI 应用",
        "icon": null,
        "pid": 0,
        "id": "ff8080818dc0b796018dc0b7d01c03f7"
    },
    {
        "path": "/app/work",
        "component": () => import('../views/application/app/Work.vue'),
        "name": "工作台",
        "icon": "/menu/6.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "ff8080818dc0b796018dc0b7d01c03f6"
    },
    /*{
        "path": "/app/collect",
        "component": () => import('../views/application/app/Collect.vue'),
        "name": "我的收藏",
        "icon": "/menu/2.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "ff8080818dc0b796018dc0b7d01c03f5"
    },*/
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "微信公众号",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d99b33a7d5484474.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d5070000"
    },
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "小红书",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230808/64d2574855c6523695.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d50f0001"
    },
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "短视频/直播",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda7344e24291590.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d5100002"
    },
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "商品文案",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda7a32c5ef70385.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d5110003"
    },
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "影视/书籍",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/65790fbace20068258.jpg",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d5120004"
    },
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "社交媒体",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda7241373036358.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d5120005"
    },
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "创作助手",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda7567198e48695.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d5130006"
    },
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "电子商务",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda73c4fab127855.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d5140007"
    },
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "品牌营销",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda72be21d531787.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d5140008"
    },
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "商业助手",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda74f0403555417.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d5150009"
    },
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "合同助手",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230920/650b02c44a87c33703.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d515000a"
    },
    {
        "path": "/app/index",
        "component": () => import('../views/application/app/Index.vue'),
        "name": "学术教育",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/6598132ebf6e278473.png",
        "pid": "ff8080818dc0b796018dc0b7d01c03f7",
        "id": "4028943c8e0863a4018e0863d516000b"
    },
    {
        "redirect": "/chat/chat",
        "path": "/chat",
        "name": "AI 对话",
        "icon": null,
        "pid": 0,
        "id": "ff8080818dc0b796018dc0b7d02303f9"
    },
    {
        "path": "/chat/chat",
        "component": () => import('../views/application/chat/Chat.vue'),
        "name": "AI对话聊天",
        "icon": "/menu/1.png",
        "pid": "ff8080818dc0b796018dc0b7d02303f9",
        "id": "1028943c8e0863a4018e0863d5070000"
    },
    {
        "path": "/chat/create",
        "component": () => import('../views/application/chat/Create.vue'),
        "name": "AI创作工具",
        "icon": "/menu/7.png",
        "pid": "ff8080818dc0b796018dc0b7d02303f9",
        "id": ""
    },
    {
        "path": "/chat/prompt",
        "component": () => import('../views/application/chat/Prompts.vue'),
        "name": "提示词",
        "icon": "/menu/9.png",
        "pid": "ff8080818dc0b796018dc0b7d02303f9",
        "id": "1028943c8e0863a4018e0863d5070002"
    },
    {
        "path": "/chat/index",
        "component": () => import('../views/application/chat/Create.vue'),
        "name": "文案创作",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231019/6530f7d59c07588292.png",
        "pid": "ff8080818dc0b796018dc0b7d02303f9",
        "id": "458dce28-793c-428e-894a-bfe2d000b91a"
    },
    {
        "path": "/chat/index",
        "component": () => import('../views/application/chat/Create.vue'),
        "name": "市场营销",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda9312c2e351699.png",
        "pid": "ff8080818dc0b796018dc0b7d02303f9",
        "id": "bc47caa1-5b85-49d2-952d-5de7beadeb60"
    },
    {
        "path": "/chat/index",
        "component": () => import('../views/application/chat/Create.vue'),
        "name": "写作辅助",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda921639fc34512.png",
        "pid": "ff8080818dc0b796018dc0b7d02303f9",
        "id": "08191856-4d97-496d-95c7-75a73335e315"
    },
    {
        "path": "/chat/index",
        "component": () => import('../views/application/chat/Create.vue'),
        "name": "人力资源",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda96b4449291590.png",
        "pid": "ff8080818dc0b796018dc0b7d02303f9",
        "id": "8b082df4-a9bd-4f8a-82d5-0c4fbc405848"
    },
    {
        "path": "/chat/index",
        "component": () => import('../views/application/chat/Create.vue'),
        "name": "企业管理",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda965099fc31787.png",
        "pid": "ff8080818dc0b796018dc0b7d02303f9",
        "id": "85a80dd2-ba2f-4f2b-b0c7-5e4ac035dc3e"
    },
    {
        "path": "/chat/index",
        "component": () => import('../views/application/chat/Create.vue'),
        "name": "教育学术",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231019/6530f8ae4661c42107.png",
        "pid": "ff8080818dc0b796018dc0b7d02303f9",
        "id": "4cf28f4d-b84f-4823-b795-21b7d293b259"
    },
    {
        "path": "/chat/prompt/details",
        "component": () => import('../views/application/chat/components/PromptsDetails.vue'),
        "name": "发现提示词",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230918/65084b4645a2375212.png",
        "pid": "",
        "id": ""
    },

    {
        "redirect": "/drawer/index",
        "path": "/industry",
        "name": "AI 绘画",
        "icon": null,
        "pid": 0,
        "id": "ff8080818dc0b796018dc0b7d02b03fb"
    },
    {
        "path": "/drawer/index",
        "component": () => import('../views/application/drawer/Index.vue'),
        "name": "专业绘画",
        "icon": "/menu/3.png",
        "pid": "ff8080818dc0b796018dc0b7d02b03fb",
        "id": "ff8080818dc0b796018dc0b7d02b03fdd"
    },
    {
        "path": "/drawer/index",
        "component": () => import('../views/application/drawer/ImageCreate.vue'),
        "name": "开始创作",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230918/650853de7204c69581.png",
        "pid": "ff8080818dc0b796018dc0b7d02b03fb",
        "id": "4028943c8e08a0ee018e08a970660006"
    },
    // {
    //     "path": "/drawer/index",
    //     "component": () => import('../views/application/drawer/Index.vue'),
    //     "name": "线下实体",
    //     "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230918/65084cf6b81e745778.png",
    //     "pid": "ff8080818dc0b796018dc0b7d02b03fb",
    //     "id": "4028943c8e08a0ee018e08a9706f0007"
    // },
    // {
    //     "path": "/drawer/index",
    //     "component": () => import('../views/application/drawer/Index.vue'),
    //     "name": "企业助手",
    //     "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cda746914ff95786.png",
    //     "pid": "ff8080818dc0b796018dc0b7d02b03fb",
    //     "id": "4028943c8e08a0ee018e08a9706f0008"
    // },
    // {
    //     "path": "/drawer/index",
    //     "component": () => import('../views/application/drawer/Index.vue'),
    //     "name": "餐饮服务",
    //     "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230918/65083f2cae55c23316.png",
    //     "pid": "ff8080818dc0b796018dc0b7d02b03fb",
    //     "id": "4028943c8e08a0ee018e08a970700009"
    // },
    // {
    //     "path": "/drawer/index",
    //     "component": () => import('../views/application/drawer/Index.vue'),
    //     "name": "电商零售",
    //     "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230918/650842cdd282548523.png",
    //     "pid": "ff8080818dc0b796018dc0b7d02b03fb",
    //     "id": "4028943c8e08a0ee018e08a97070000a"
    // },
    // {
    //     "path": "/drawer/index",
    //     "component": () => import('../views/application/drawer/Index.vue'),
    //     "name": "教育行业",
    //     "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230918/650843a1725d770641.png",
    //     "pid": "ff8080818dc0b796018dc0b7d02b03fb",
    //     "id": "4028943c8e08a0ee018e08a97071000b"
    // },
    // {
    //     "path": "/drawer/index",
    //     "component": () => import('../views/application/drawer/Index.vue'),
    //     "name": "数码电器",
    //     "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230918/65084a36f1c5016511.png",
    //     "pid": "ff8080818dc0b796018dc0b7d02b03fb",
    //     "id": "4028943c8e08a0ee018e08a97072000c"
    // },
    // {
    //     "path": "/drawer/index",
    //     "component": () => import('../views/application/drawer/Index.vue'),
    //     "name": "化妆护肤",
    //     "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230918/65084b4645a2375212.png",
    //     "pid": "ff8080818dc0b796018dc0b7d02b03fb",
    //     "id": "4028943c8e08a0ee018e08a97073000d"
    // },

    // {
    //     path: '/chatRobot',
    //     name: '对话聊天',
    //     layout: "dashboard",
    //     component: () => import(/* webpackChunkName: "dashboard" */ '../views/ai/ChatRobot.vue'),
    // },
    // {
    //     id: 1,
    //     pid: 0,
    //     path: '/app',
    //     redirect: '/app/index1',
    //     name: 'AI 应用',
    // },
    // {
    //     id: 2,
    //     pid: 1,
    //     path: '/app/index2',
    //     iconClass: 'aa',
    //     name: '创作助手',
    //     component: () => import('../views/application/Index2.vue')
    // },
    // {
    //     id: 3,
    //     pid: 1,
    //     path: '/app/index1',
    //     name: '品牌直销',
    //     iconClass: 'bb',
    //     component: () => import('../views/application/Index1.vue'),
    // },
    //
    // {
    //     id: 4,
    //     pid: 0,
    //     path: '/chat',
    //     redirect: '/chat/index3',
    //     name: 'AI 对话',
    // },
    // {
    //     id: 5,
    //     pid: 4,
    //     path: '/chat/index3',
    //     iconClass: 'aa',
    //     name: '创作对话',
    //     component: () => import('../views/application/Index3.vue')
    // },
    // {
    //     id: 6,
    //     pid: 4,
    //     path: '/chat/index4',
    //     iconClass: 'aa',
    //     name: '对话助手',
    //     component: () => import('../views/application/Index4.vue')
    // },


]

// function menus() {
//     request({
//         url: `${APICONFIG}/menu`,
//         method: 'get',
//     }).then(res => {
//         routes = routes.concat(res.data)
//         routes.forEach(item => {
//             if (item.component) {
//                 item.component = () => import(item.component)
//             }
//         })
//         console.log(1111, res)
//     });
// }
//
// menus()


function addLayoutToRoute(route, parentLayout = "app-dashboard") {
    route.meta = route.meta || {};
    route.meta.layout = route.layout || parentLayout;

    if (route.children) {
        route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.layout));
    }
    return route;
}


routes.forEach(route => {
    if (route.children) {
        route.children.forEach(item => {
            routes.push(item)
        })
    }
})
routes = routes.map((route) => addLayoutToRoute(route));


const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
        return {
            x: 0,
            y: 0,
            behavior: 'smooth',
        }
    }
})


export default router







/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from 'vue'
import Antd, {Icon} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import AppDashboardLayout from './layouts/AppDashboard.vue'
import router from './router'
// import './plugins/click-away'
// 适配iconfont
// let iconCssName = "//at.alicdn.com/t/font_2901769_9480kchz9wk";
let iconCssName = "/icon/iconfont";

import store from './store'

const IconFont = Icon.createFromIconfontCN({
    scriptUrl: `${iconCssName}.js`,
});

import './scss/app.scss';

//富文本，全局注入
import('./ext')

Vue.use(Antd);

Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);
Vue.component("layout-app-dashboard", AppDashboardLayout);
Vue.component('IconFont', IconFont);


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')